import React from "react"

import SiteLayout from "../components/SiteLayout/SiteLayout"
import SEO from "../components/SEO/SEO"
import ContactUs from "../components/ContactUs/ContactUs"

import "./captcha-fail.css"

const ContactUsPage = () => (
  <SiteLayout>
    <section className="secondary-banner blue-p-2" id="captcha-fail">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 free-sample-rpt-msg">
            <h1>
              <strong>Something's Wrong!</strong>
            </h1>
            <hr />
            <h3>We detected that you are failing on captcha test.</h3>
            <h3>
              <a href="/">Please go back and try again.</a>
            </h3>
          </div>
        </div>
      </div>
    </section>
  </SiteLayout>
)

export default ContactUsPage
